let keyupTimer = 0;
const keyupTimeout = 300;
let firstLoadCity = true;
let inputCity = '';
// noinspection OverlyComplexFunctionJS
window.loadCitiesOnChangeCountry = function(
    $selectCountry,
    selectedCountry,
    $selectCity,
    selectedCity,
    $selectRegion,
    selectedRegion
) {
    $("#countrySelect, #regionSelect").on('change', function() {
        let selectedCityParam,
            selectedRegionParam,
            selectedCountryParam;
        if (firstLoadCity) {
            selectedCityParam = selectedCity;
            selectedRegionParam = selectedRegion;
            selectedCountryParam = selectedCountry;
            firstLoadCity = false;
        } else {
            selectedCityParam = $selectCity.find(':selected').val();
            selectedRegionParam = $selectRegion.find(':selected').val();
            selectedCountryParam = $selectCountry.find(':selected').val();
        }

        let params = {};

        if (!isNaN(selectedCountryParam)) {
            params.countryId = selectedCountryParam;
            if (selectedRegionParam !== -1) {
                params.regionId = selectedRegionParam;
            }
            if (selectedCityParam !== -1) {
                params.cityId = selectedCityParam;
            }

            $.post(CONFIG.URL.cityLoad, params, function(data) {
                /**
                 * @property {object} data
                 * @property {object} data.cities
                 * @property {object} data.regions
                 */
                if (data.cities) {
                    $selectCity.attr('disabled', null);
                    drawSelect($selectCity, [selectedCityParam], data.cities);
                }
                if (data.regions) {
                    $selectRegion.attr('disabled', null);
                    drawSelect($selectRegion, [selectedRegionParam], data.regions, true);
                }
            });
        }
    });

    $('body').on('input', '#citySelect_chosen .chosen-search-input', function(e) {
        let selectedRegionParam = $selectRegion.find(':selected').val();
        let selectedCountryParam = $selectCountry.find(':selected').val();

        let cities = [];
        let params = {};
        let cityTmp = $(e.target).val();
        if (cityTmp !== inputCity) {
            inputCity = cityTmp;
        }

        if (!isNaN(selectedCountryParam)) {
            params.countryId = selectedCountryParam;
            params.search = cityTmp;
            if (selectedRegionParam !== -1) {
                params.regionId = selectedRegionParam;
            }

            clearTimeout(keyupTimer);
            keyupTimer = setTimeout(function () {
                $.post(CONFIG.URL.cityLoadAutocomplete, params, function(data) {
                    $.each(data, function(index, value) {
                        cities.push({
                            'id': value['id'],
                            'name': value['label'],
                            'region': Number(selectedRegionParam)
                        });
                    });
                    if (cities.length > 0) {
                        $selectCity.attr('disabled', null);
                        drawSelect($selectCity, [selectedCity], cities);
                        $(e.target).val(inputCity);
                    }
                });
            }, keyupTimeout);
        }
    });

    $selectCountry.chosen($.extend({}, CONFIG.CHOSEN, {
        allow_single_deselect: false
    })).trigger('change');

    $selectCity.chosen($.extend({}, CONFIG.CHOSEN, {
        allow_single_deselect: false,
        disable_search_threshold: 0,
        min_symbols: 1
    })).on('chosen:showing_dropdown', function() {
        $citySelectChosen.find('.chosen-results').height(0);
    });

    let $citySelectChosen = $("#citySelect_chosen");
    $citySelectChosen.find('.chosen-search input').on('keyup', function() {
        let searchVal = $(this).val();
        if(searchVal.length > 0){
            $citySelectChosen.find('.chosen-results').height('auto');
        } else {
            $citySelectChosen.find('.chosen-results').height(0);
        }
    });

    let drawSelect = function($select, selectedIds, data, notShow){
        let html = '<option value=""></option>';
        for (const item of data) {
            const selected = item.id === Number(selectedIds[0]) ? ' selected' : '';
            const name = item.name.indexOf('</') + 1 ? item.name : item.name.replace('<', '&lt;').replace('>', '&gt;');
            html += '<option value="' + item.id + '"'+selected+'>' + name + '</option>';
        }

        $select.html(html);

        if(!notShow) {
            $select.closest('.form-group').show();
        }

        if($select.next('.chosen-container').length){
            $select.trigger('chosen:updated');
        } else {
            $select.chosen($.extend({}, CONFIG.CHOSEN, {
                allow_single_deselect: false
            }));
        }
    };
};
